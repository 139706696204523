.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 50px 0;
  margin-bottom: 80px;
}

.contact-content {
  display: flex;
  background-color: white;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-width: 90%;
  width: 100%;
  gap: 2em;
  flex-direction: row;
}

.header_contacts {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #be9439;
}

.contact-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info p {
  font-size: 1.1em;
  color: #555;
  margin-bottom: 10px;
}

.contact-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.form-group input {
  padding: 0.8em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  color: #333;
}

.form-group input::placeholder {
  color: #aaa;
}

.contact-button {
  background-color: #be9439;
  color: white;
  border: none;
  padding: 1em;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  display: block;
  margin-top: 10px;
}

.contact-button:hover {
  background-color: #a6802d;
  transform: scale(1.05);
}

.message {
  margin-top: 20px;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.logo-container {
  margin-top: 20px;
  text-align: center;
}

.contact-logo {
  width: 60%;
  height: auto;
  position: relative;
  right: 115px;
}

@media (max-width: 768px) {
  .contact-section {
    height: auto;
    padding: 30px 0;
  }

  .contact-content {
    flex-direction: column;
    text-align: center;
  }

  .contact-info, .contact-form {
    flex: none;
    width: 100%;
  }

  .contact-logo {
    width: 100%;
    height: auto;
    position: relative;
    right: 0px;
  }

  .header_contacts {
    font-size: 2em;
  }
}
