.footer {
  background-color: #121211;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.social-media-icons {
  margin-top: 10px;
}

.social-media-icons a {
  color: #fff;
  margin: 0 10px;
  font-size: 24px;
  transition: color 0.3s;
}

.social-media-icons a:hover {
  color: #f0f0f0;
}
