.pricing-section {
  padding: 4em 2em;
  background-color: #ffffff;
  text-align: center;
}

.pricing-section h1 {
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #333;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
}

.paragraph_p_1 {
  font-size: 40px;
  color: #be9439;
}

.marble-price-card {
  width: 300px;
  padding: 2em;
  background-size: cover;
  background-position: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.marble-price-content {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1em;
  border-radius: 10px;
  text-align: left;
}

.marble-price-content h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.marble-price-content p {
  font-size: 1em;
  margin-bottom: 1em;
}

.marble-price-content ul {
  list-style: none;
  padding: 0;
}

.marble-price-content ul li {
  margin-bottom: 0.5em;
}

.marble-price-content ul li.included::before {
  content: '✔️';
  color: green;
  margin-right: 0.5em;
}

.marble-price-content ul li.not-included::before {
  content: '❌';
  color: #be9439;
  margin-right: 0.5em;
}

.select-button {
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.select-button:hover {
  background-color: #555;
}
