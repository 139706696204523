body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  padding-top: 4em; /* Padding to prevent content overlap with fixed header */
}

section {
  padding: 2em;
  text-align: center;
}

footer {
  background-color: #333;
  color: white;
  padding: 1em;
  text-align: center;
}

/* Header styles moved to Header.css */
