.carousel-container {
  position: relative;
  margin-top: -65px;
}

.carousel .slide img {
  height: 600px; /* Задаем высоту карусели */
  width: 100%; /* Заполняем всю ширину контейнера */
  object-fit: cover; /* Для того, чтобы изображение заполняло всю высоту */
  display: block; /* Убираем пробелы снизу изображения */
  margin: 0 auto; /* Центрируем изображение */
}

.carousel-slide {
  position: relative;
}

.bg_ground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
  z-index: 1; /* Размещаем затемнение перед изображением */
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  font-size: 2em;
  padding: 0.5em 1em;
  cursor: pointer;
  z-index: 1000;
}

.carousel-arrow.prev {
  left: 10px;
}

.carousel-arrow.next {
  right: 10px;
}

.carousel-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel-indicator.active {
  background-color: white;
}
