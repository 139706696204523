.products-section {
  padding: 4em 2em;
  background-color: #ffffff;
  text-align: center;
}

.products-section h1 {
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #be9439;
}

.products-grid {
  display: flex;
  justify-content: center;
  gap: 2em;
  flex-wrap: wrap;
}

.product-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 300px;
  padding: 2em;
  text-align: left;
  position: relative; /* Добавлено для позиционирования логотипа */
}

.heaeder_craft {
  margin-bottom: 60px;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-number {
  font-size: 2em;
  font-weight: bold;
  color: #be9439;
  margin-bottom: 1em;
}

.product-card h2 {
  font-size: 1.5em;
  margin: 0.5em 0;
  color: #121211;
}

.product-card p {
  font-size: 1em;
  color: #666;
}

.company-logo {
  position: absolute; /* Позиционирование логотипа */
  top: 10px; /* Настройте по своему вкусу */
  right: 10px; /* Настройте по своему вкусу */
  width: 50px; /* Размер логотипа */
  height: auto;
}
