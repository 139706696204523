.about-section {
    padding: 4em 2em;
    background-color: #ffffff;
    text-align: center;
  }
  
  .about-section h1 {
    font-size: 2.5em;
    margin-bottom: 1em;
    color: #333;
  }

  
  .founders {
    display: flex;
    justify-content: center;
    gap: 2em;
    margin-bottom: 2em;
  }
  
  .founder {
    text-align: center;
  }
  
  .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }

  .text_header_1 {
    color: #be9439;
    font-size: 40px;
    margin-bottom: 20px;
  }
  
  .profile-name {
    margin-top: 0.5em;
    font-size: 1em;
    color: #ffffff;
  }
  
  .about-section p {
    font-size: 1.2em;
    margin: 2em 0;
    color: #121211;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
  }
  
  .mission {
    display: flex;
    justify-content: center;
    gap: 2em;
    margin-top: 2em;
    color: #ffffff;
  }
  
  .mission-card {
    background-color: #be9439;
    color: white;
    padding: 2em;
    border-radius: 15px;
    text-align: center;
    width: 300px;
  }
  
  .mission-card h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .mission-card p {
    font-size: 1em;
  }
  
  .team {
    display: flex;
    justify-content: center;
    gap: 1em;
    flex-wrap: wrap;
  }
  
  .team-member {
    width: 80px;
    height: 80px;
  }
  
  .team-pic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
